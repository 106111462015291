@import './shared/styles/_vuetify-overrides.scss';




































































































































































































































































































































.empty-table-alert {
  color: rgba(148, 159, 182) !important;
  text-align: center !important;
  vertical-align: middle !important;
}
