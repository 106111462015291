@import './shared/styles/_vuetify-overrides.scss';
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.cassie-banner-settings-container {
	border: var(--global-cassie-settings-border);
	border-radius: 5px;
}

.banner-appearance-radio {
	display: flex;
	flex-direction: column-reverse;
	padding: 5px;
}

.cassie-banner-appearance-image {
	width: 75px;

	@media screen and (min-width: var(--global-cassie-breakpoint-lg)) {
		width: 125px;
	}
	@media screen and (min-width: var(--global-cassie-breakpoint-xl)) {
		width: 150px;
	}
}

.cassie-colour-picker-container {
	background-color: white;
}

.cassie-customise-button-container {
	@media screen and (min-width: var(--global-cassie-breakpoint-lg)) {
		width: 50%;
	}
}

.cassie-display-logo-switch {
	max-width: 50px;
}

.cassie-advanced-options-switch {
	max-width: 50px;
}

.cassie-preview-type-tabs-container {
	border-radius: 5px;
}
.cassie-preview-type-tab {
	font-size: 10px;
	color: white !important;
	text-transform: var(--cassie-tab-text-transform);
}

.cassie-preview-type-tab-active {
	background: var(--global-color-blue-darken-2);
	border-radius: 5px;
}
.cassie-preview-type-tab-active::before {
	opacity: 0.12;
}

.cassie-device-type-tab-active {
	color: var(--cassie-tab-active-text-color);
	background: var(--global-color-blue-darken-2) !important;
}

.cassie-device-type-tab {
	margin: 0 10px;
	font-size: 10px;
	color: var(--global-color-blue-darken-2);
	border: 1px solid var(--global-color-blue-darken-2);
	border-radius: 5px;
}

.cassie-preview-container {
	height: 500px;
}

.cassie-banner-logo {
	img {
		padding: 16px !important;
		max-width: 125px !important;
		float: right !important;
	}
}
.cassie-radio-group {
	.v-messages.v-messages {
		display: none;
	}
}
