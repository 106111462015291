@import './shared/styles/_vuetify-overrides.scss';






















































































































































































































































































.v-application {
	.cassie-steps-container {
		border-radius: var(--cassie-tabs-container-border-radius);
		box-shadow: var(--cassie-tabs-container-box-shadow);
		.cassie-stepper-step {
			font-size: var(--cassie-tab-font-size);
			text-transform: var(--cassie-tab-text-transform);
			color: var(--cassie-tab-inactive-text-color);
			border-right: var(--cassie-tab-border-right);
			opacity: var(--cassie-tab-opacity);
			&:nth-last-child(1) {
				border-right: none;
			}
		}
		.cassie-stepper-step-complete.cassie-stepper-step-complete {
			font-size: var(--cassie-tab-font-size);
			color: var(--cassie-tab-step-complete-text);
			text-transform: var(--cassie-tab-text-transform);
			background-color: var(--cassie-tab-step-complete-background-color);
			border-right: var(--cassie-tab-border-right);
			border-radius: var(--cassie-tab-active-border-radius);
			opacity: var(--cassie-tab-opacity);
			&:nth-last-child(1) {
				border-right: none;
			}
		}
		.cassie-active-stepper-step {
			color: var(--cassie-tab-step-active-text-color);
			background-color: var(--cassie-tab-step-active-background-color);
			border-radius: var(--cassie-tab-active-border-radius);
		}
	}
}
