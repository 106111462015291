@import './shared/styles/_vuetify-overrides.scss';
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.pre-banner-appearance-radio {
	display: flex;
	flex-direction: column-reverse;
	padding: 5px;
}

.cassie-prebanner-appearance-image {
	width: 125px;

	@media screen and (min-width: var(--global-cassie-breakpoint-lg)) {
		width: 150px;
	}
	@media screen and (min-width: var(--global-cassie-breakpoint-xl)) {
		width: 200px;
	}
}

.cassie-colour-picker-container {
	background-color: white;
}

.cassie-radio-group {
	.v-messages.v-messages {
		display: none;
	}
}
