@import './shared/styles/_vuetify-overrides.scss';























































































































































































































































































































































































































































































































































































































































































































































































































































































.cassie-disabled-button {
	color: var(--global-cassie-disabled-button-color);
	background-color: var(--global-cassie-disabled-button-background-color);
	border: var(--global-cassie-disabled-button-border);
	-webkit-box-shadow: revert;
	box-shadow: revert;
}

.cassie-disabled-textarea {
	padding: 10px !important;
	color: var(--global-cassie-disabled-textarea-color);
	border: var(--global-cassie-disabled-textarea-border);
}

.cassie-description-field-container > div {
	padding-top: 16px !important;
}

.cassie-overlay-card {
	max-height: 75vh;
	overflow-y: auto;
}

.cassie-appearance-settings-container {
	border: var(--global-cassie-settings-border);
	border-radius: 5px;
}

.cassie-customise-button-container {
	width: 50%;
}

.cassie-colour-picker-container {
	background-color: white;
}

.profile-form-appearance-radio {
	display: flex;
	flex-direction: row;
	padding: 0px 5px;
}

@media (max-width: var(--global-cassie-breakpoint-lg)) {
	.cassie-overlay-card {
		max-height: 85vh;
	}
}
