@import './shared/styles/_vuetify-overrides.scss';













































































































.cassie-cookie-category {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cookie-modal-secondary-color);
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  &--active {
    background-color: var(--cassie-submit-preferences-button-color);
  }
}
.cassie-cookie-modal-group {
  display: flex;
  flex-wrap: wrap;
}
.cassie-cookie-modal--group-head-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.cassie-cookie-group--heading {
  margin: 0 2px;
  font-size: 14px;
  line-height: 1.6;
  color: var(--cookie-modal-text-color);
}

.cassie-cookie-group--description {
  width: 75%;
  margin: 0 0 10px 20px;
  font-size: 12px;
  line-height: 1.6;
}
