@import './shared/styles/_vuetify-overrides.scss';















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
	color: unset;
}
pre {
	display: block;
	font-family: monospace;
	white-space: pre;
	margin: 1em 0;
}
.cassie-scripts-type-tabs-container {
	border-radius: 5px;
}

.v-application {
	.v-tabs-bar__content {
		.cassie-scripts-type-tab,
		.v-tab {
			font-size: 12px;
			color: var(--cassie-tab-secondary-text-color);
			text-transform: var(--cassie-tab-text-transform);
		}
		.cassie-scripts-type-tab-active {
			background: var(--cassie-tab-secondary-background-color);
			border-radius: 5px;
		}
		.v-tab--active.v-tab:not(:focus):before,
		.cassie-scripts-type-tab-active::before {
			opacity: 0.12;
		}
	}
}
