@import './shared/styles/_vuetify-overrides.scss';
























































































































































































































































































































































































































































































































.cassie-device-type-tab-active {
	color: white !important;
}
.cookie-modal {
  margin-right: 1rem;
  // width: 100%;
  flex-grow: 3;
  position: relative;
  &--mobile {
    flex-grow: 1;
    margin-bottom: 1rem;
  }
}
.footer-extra {
	justify-content: space-between;
  &--panels {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}
.details {
  margin-left: auto;
  flex-grow: 4;
  border-left: 3px solid white;
  padding-left: 1rem;
  width: 60%;
  position: relative;
  margin-right: auto;
  &--mobile {
    margin: 0;
    width: 100%;
    border: none;
    padding-left: 0;
  }
  &--desktop {
    padding-top: 1rem;
  }
  &--tablet {
    padding-top: 1rem;
  }
  .footer-extra-image {
    position: absolute;
    bottom: 0;
    right: 0;
    &--panels {
      left: 3;
      right: initial;
    }

    img {
      bottom: 0;
    }
  }
  > .details-header {
    margin-bottom: 1rem;
    font-size: 18px;
  }
}
.footer-extra-image ::v-deep img {
  max-height: 100%;
  max-width: 100%;
}
.cookie-list {
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  border: 2px solid var(--cookie-modal-text-color);
  border-radius: 5px;
  &--mobile {
    margin-left: 0;
  }
  > .cookie {
    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
    list-style: none;
    border-bottom: 2px solid var(--cookie-modal-text-color);
    margin-bottom: 5px;
    .cookie-name {
      font-weight: 500;
    }
    .cookie-header {
      display: flex;
      justify-content: space-between;
    }
    .cookie-description {
      font-size: 13px;
      margin-top: 0.5rem;
    }
  }
  > .cookie:last-child {
    border-bottom: none;
  }
}
.footer {
  width: 100%;
  font-size: 12px;
  line-height: 1.6;
  &-extra {
    margin-top: 1rem;
    &-image {
      position: relative;
      width: 30%;
      a {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        margin: 0;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }
}
.save-preferences {
  width: 10rem;
  max-height: 50px;
  min-height: 50px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--cassie-submit-preferences-button-text-color);
  cursor: pointer;
  background-color: var(--cassie-submit-preferences-button-color);
  border-color: var(--cassie-submit-preferences-button-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-image: initial;
}
.cassie-cookie-modal {
  position: relative;
  z-index: 192;
  display: flex;
  flex-direction: column;
  flex-wrap: initial;
  width: 25rem;
  height: 100%;
  padding: 20px;
  overflow: hidden scroll;
  color: var(--cookie-modal-text-color);
  background-color: var(--cookie-modal-background-color);
  inset: 0;
  &--left {
    border-right: var(--cassie-submit-preferences-button-color);
    border-right-width: 1rem;
    border-right-style: solid;
    align-self: flex-start;
  }
  &--right {
    align-self: flex-end;
    border-left: var(--cassie-submit-preferences-button-color);
    border-left-width: 1rem;
    border-left-style: solid;
  }
  &--panels {
    width: 55rem;
    max-width: 100%;
    height: 500px;
    flex-direction: row;
    margin: auto;
    &--mobile {
      height: 100%;
      flex-direction: column;
    }
  }
  &--middle {
    width: 33rem;
    max-width: 100%;
    height: 500px;
    &--mobile {
      height: 100%;
    }

    margin: auto;
  }
}
.cassie-cookie-modal--accept-all {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.cassie-toggle-switch--checkbox {
  position: relative;
  right: 48px;
  bottom: 27px;
  z-index: 1;
  width: 90px;
  height: 24px;
  cursor: pointer;
  opacity: 0;
}
.cassie-cookie-modal--header--content {
  width: 100%;
  padding-top: 10px;
  margin: 0 0 10px;
  font-size: 12px;
  line-height: 1.6;
  overflow-wrap: break-word;
  border-color: var(--cookie-modal-secondary-color);
  border-top-style: solid;
  border-top-width: 2px;
}

.cassie-cookie--child--heading {
  width: 60%;
  margin: 0;
  font-size: 12px;
  line-height: 1.6;
}
.cassie_preview_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 680px;
  margin: auto;
  background-color: rgba(0,0,0,.7);
  box-shadow: inset 0 0 0 2000px rgba(179, 173, 176, 0.3);
}
.cassie-toggle-switch--status {
  position: relative;
  top: -2px;
  right: 50px;
  font-size: 12px;
}
.cassie-cookie--child--description {
  width: 75%;
  margin: 5px 0;
  font-size: 12px;
  line-height: 1.6;
}

.cassie-cookie-modal--main {
  display: flex;
  flex-shrink: 0;
  align-self: flex-start;
  width: 100%;
  border-color: var(--cookie-modal-secondary-color);
  border-width: 2px 2px 0;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: initial;
  border-left-style: solid;
  border-image: initial;
  &--panels {
    border: none;
    display: block;
  }
}

.cassie-cookie-modal--cookies--container {
  width: 100%;
}
.cassie-modal-header {
  width: 50%;
  &--middle {
    margin: auto;
  }
  // &--panels {
  //   margin: auto;
  // }
}
.cassie-toggle-switch--checkbox {
  position: relative;
  right: 48px;
  bottom: 27px;
  z-index: 1;
  width: 90px;
  height: 24px;
  cursor: pointer;
  opacity: 0;
}
.cassie-toggle-switch {
  position: relative;
  width: 50px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
}
.tab {
  padding-bottom: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1.1rem;
  cursor: pointer;
  border-bottom-color: var(--cookie-modal-text-color);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  &--active {
    border-bottom-color: var(--cookie-modal-active-tab);
    transition: 0.2s border-bottom-color ease-in-out;
  }
}
.cassie-cookie-modal--footer-image {
  width: 7rem;
}
.cassie_preview_container--tablet {
  width: 100%;
  max-width: 1024px;
}
.cassie-cookie-modal--header-image > img {
  max-height: 50%;
  max-width: 50%;
}
.cassie-cookie-modal--header-image {
  margin: 10px 0;
  &--middle {
    max-width: 50%;
  }
  &--panels {
    max-width: 50%;
  }
}
.cassie_preview_container--mobile {
  width: 100%;
  max-width: 414px;
}

.cassie--gpc--flag {
  color: var(--cassie-submit-preferences-button-text-color);
  background-color: var(--cassie-submit-preferences-button-color);
  font-size: 8px;
  border-radius: 10px;
  padding: 2px 5px;
  margin-bottom: 2px;
  margin-left: 5px;
  font-weight: bold;
}
