@import './shared/styles/_vuetify-overrides.scss';















































































































































































































































































































































































































































































































.cassie-pre-banner-button {
  width: 70%;
  padding: 10px 0;
  margin: 8px 0;
  font-family: 'poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: var(--cassie-preview-text-color);
  overflow-wrap: break-word;
  cursor: pointer;
  border-color: var(--cassie-preview-primary-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}
.cassie-pre-banner-button--bottom {
  width: 30%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 2%;
  margin-left: 2%;
}
.cassie-pre-banner-button--top {
  width: 30%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 2%;
  margin-left: 2%;
}
.cassie-pre-banner .cassie-view-all {
  margin-top: 20px;
  background-color: var(--cassie-preview-primary-color);
  border: 0;
  border-color: var(--cassie-preview-primary-color);
  border-bottom: 1px solid;
}
.cassie-view-all--button--icon {
  vertical-align: middle;
  fill: #fff;
}
.cassie-pre-banner .cassie-accept-all {
  color: #fff;
  background-color: #071725;
  border: 1px solid;
  border-color: var(--cassie-preview-primary-color);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-width: 1px;
}
.cassie-pre-banner--bottom {
  align-self: flex-end;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  background-color: var(--cassie-preview-secondary-color);
  border-right-color: var(--cassie-preview-primary-color);
  border-right-style: solid;
  border-right-width: 15px;
  border-left-color: var(--cassie-preview-primary-color);
  border-left-style: solid;
  border-left-width: 15px;
}
.cassie-pre-banner--top {
  align-self: flex-start;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  background-color: var(--cassie-preview-secondary-color);
  border-right-color: var(--cassie-preview-primary-color);
  border-right-style: solid;
  border-right-width: 15px;
  border-left-color: var(--cassie-preview-primary-color);
  border-left-style: solid;
  border-left-width: 15px;
}
.cassie-pre-banner-text {
  align-self: center;
  font-size: 13px;
  color: var(--cassie-preview-text-color);
}
.cassie-pre-banner-text--mobile {
  flex: 100%;
}
.cassie-pre-banner-text--tablet {
  flex: 100%;
}
.cassie-pre-banner-text--desktop {
  flex: 60%;
}
.cassie-pre-banner-text--bottom {
  margin-bottom: 0 !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
}
.cassie-pre-banner-button-container--mobile {
  flex: 100%;
}
.cassie-pre-banner-button-container--tablet {
  flex: 100%;
}
.cassie-pre-banner-button-container--desktop {
  flex: 40%;
}
.cassie-pre-banner-button-container--top {
  display: flex;
  flex-direction: row;
  width: 40%;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--cassie-preview-text-color);
}
.cassie-pre-banner-button-container--bottom {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--cassie-preview-text-color);
}
.cassie-pre-banner--center {
  flex-wrap: nowrap;
  align-self: center;
  justify-content: center;
  width: 100%;
  min-height: 15rem;
  padding: 20px 0;
  margin: 0 30%;
  background-color: var(--cassie-preview-secondary-color);
  border-right: 15px solid;
  border-right-color: var(--cassie-preview-primary-color);
  border-left: 15px solid;
  border-left-color: var(--cassie-preview-primary-color);
  border-radius: 10px;
}
.cassie-pre-banner--mobile {
  margin: 0;
}
.cassie-pre-banner-text--center {
  padding-left: 2rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--cassie-preview-text-color);
}
.flex-wrap {
  flex-wrap: wrap;
}
.cassie-pre-banner-text--desktop {
  width: 60%;
}
.cassie-pre-banner-text--mobile {
  width: 100%;
}
.cassie-pre-banner-text--tablet {
  width: 100%;
}
.cassie-pre-banner-button-container--center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 40%;
  margin: 0;
}

.cassie_preview_type_tabs_container {
  border-radius: 5px;
}
.cassie_preview_type_tab {
  font-size: 10px;
  color: white !important;
  text-transform: var(--cassie-tab-text-transform);
}

.cassie_preview_type_tab_active {
  background: var(--cassie-tab-active-preview);
  border-radius: 5px;
}
.cassie_preview_type_tab_active::before {
  opacity: 0.12;
}

.cassie_device_type_tab_active {
  color: white !important;
  background: var(--global-color-blue-darken-2);
}

.cassie_device_type_tab {
  margin: 0 10px;
  font-size: 10px;
  color: var(--global-color-blue-darken-2);
  border: 1px solid var(--global-color-blue-darken-2);
  border-radius: 5px;
}
p {
  margin-bottom: 0%;
}
.cassie_preview_container {
  width: 100%;
  height: 500px;
	background-color: rgba(0,0,0,.7);
  box-shadow: inset 0 0 0 2000px rgba(179, 173, 176, 0.3);
}
.cassie_preview_container--tablet {
  width: 100%;
  max-width: 1024px;
}
.cassie_preview_container--mobile {
  width: 100%;
  max-width: 414px;
}

.cassie-manage-cookies--button {
  max-width: 150px;
  min-height: 44px;
  max-height: 50px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
  padding: 0 10px;
  margin: 10px;
}
