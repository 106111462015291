@import './shared/styles/_vuetify-overrides.scss';

















































































































































































































































































.cassie-disabled-textarea {
  padding: 10px !important;
  color: var(--global-cassie-disabled-textarea-color);
  border: var(--global-cassie-disabled-textarea-border);
}
