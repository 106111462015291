@import './shared/styles/_vuetify-overrides.scss';



















































































































































































































.cassie-cookie-modal-group {
  display: flex;
  flex-wrap: wrap;
  border-color: var(--cookie-modal-secondary-color);
  border-bottom-style: solid;
  border-bottom-width: 2px;
}
.cassie-cookie-modal--group-head-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}
.cassie-expand-cookies--container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  cursor: pointer;
}
.expander {
  width: 1rem;
  &--disabled {
    pointer-events: none;
    cursor: none;
  }
  .expand-icon {
    fill: var(--cassie-submit-preferences-button-color);
    &--open {
      transform: scaleY(-1);
    }
  }
}
.cookie {
  width: 100%;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  margin-bottom: 10px;
  &-description {
    margin: 0 1rem;
    font-size: 12px;
    line-height: 1.6;
		width: 75%;
  }
  &-header {
    width: 100%;
    margin-bottom: 0.5rem;
		justify-content: space-between;
    &-text {
      margin: 0 1rem;
      font-size: 12px;
      font-weight: 700;
      line-height: 1.6;
      color: var(--cookie-modal-secondary-color);
    }
  }
}
.expand-text {
  font-size: 12px;
  margin-right: 0.2rem;
}
.cassie-cookie-group--heading {
  margin: 0 2px;
  font-size: 14px;
  line-height: 1.6;
  color: var(--cookie-modal-text-color);
}

.cassie-cookie-group--description {
  width: 75%;
  margin: 0 0 10px 20px;
  font-size: 12px;
  line-height: 1.6;
}

.cassie-cookie-children--container {
	padding: 10px;
  min-width: 100%;
	background-color: var(--cookie-modal-text-color);
	color: var(--cookie-modal-secondary-color);
}

.cassie--gpc--flag {
  color: var(--cassie-submit-preferences-button-text-color);
  background-color: var(--cassie-submit-preferences-button-color);
  font-size: 8px;
  border-radius: 10px;
  padding: 2px 5px;
  margin-bottom: 2px;
  margin-left: 5px;
  font-weight: bold;
}
