@import './shared/styles/_vuetify-overrides.scss';









































































































































































































































































































































































































































































.cassie-overlay-card {
  max-height: 75vh;
  display: flex !important;
  flex-direction: column;
  overflow-y: auto !important;
}
