@import './shared/styles/_vuetify-overrides.scss';















































.cassie-stepper {
	box-shadow: var(--global-cassie-tabs-container-box-shadow);

}

.cassie-step {
	border-right: var(--global-cassie-tab-border-right);
	&:nth-last-child(1) {
		border-right: none;
	}
}

.cassie-step-complete.cassie-step-complete {
	font-size: var(--cassie-tab-font-size);
	text-transform: var(--cassie-tab-text-transform);
	background-color: var(--cassie-tab-step-complete-background-color);
	border-right: var(--cassie-tab-border-right);
	border-radius: var(--cassie-tab-active-border-radius);
	opacity: var(--cassie-tab-opacity);
	&:nth-last-child(1) {
		border-right: none;
	}
}

.cassie-active-step-opacity {
	opacity: 1 !important;
}
